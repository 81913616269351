<template>
  <el-dialog
    title="文件预览"
    :visible.sync="value"
    :before-close="onHandleClose"
    :close-on-click-modal="true"
    :append-to-body="true"
    @opened="onSetActiveItem"
  >
    <div class="block">
      <el-carousel
        v-loading="loading"
        ref="refCarousel"
        trigger="click"
        :autoplay="false"
        :class="{ 'hide-btn': fileList.length <= 1 }"
      >
        <el-carousel-item v-for="(item, index) in fileList" :key="index">
          <!-- 图片类 start -->
          <!-- <img
            v-if="imageType.includes(item.fileType)"
            :src="item.url"
            :alt="item.name"
          /> -->
          <el-image
            style="width: 100%; height: 100%"
            v-if="imageType.includes(item.fileType)"
            :src="item.url"
            :preview-src-list="[item.url]"
          >
          </el-image>
          <!-- 图片类 end -->

          <!-- 视频类 start -->
          <div
            class="video-wrapper"
            id="playerId"
            ref="videoPlayer"
            v-if="videoType.includes(item.fileType)"
          >
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="videoPlayerOptions(item.url)"
            >
            </video-player>
          </div>
          <!-- 视频类 end -->
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-dialog>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "videojs-contrib-hls";
import "videojs-flash";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

export default {
  name: "CPreviewImage",
  components: {
    videoPlayer,
  },
  props: {
    /** 显示隐藏 */
    value: {
      type: Boolean,
      default: false,
    },
    /** 文件列表 */
    fileList: {
      type: Array,
      default: () => [],
    },
    /** 当前预览下标值 */
    fileIndex: {
      type: Number,
      default: 0,
    },
    /**
     * 文件类型
     */
    fileType: {
      type: String,
      default: "png",
    },
  },
  created() {
    console.log(this.fileList, this.fileType, "fileList==");
    this.fileList.map((item) => {
      console.log(item, "000");
      item.fileType = this.getFileType(item);
    });
    console.log(this.fileList, "fileL2222ist==");
  },

  data() {
    return {
      loading: true,
      imageType: ["jpg", "jpeg", "png"], // 图片格式枚举
      videoType: ["mp4", "avi", "flv", "mov"], // 视频格式枚举
    };
  },
  watch: {
    fileType(val) {
      console.log(val, "fileType==");
    },
    fileIndex(val) {
      console.log(val, "fileIndex==");
    },
    fileList(val) {
      val.map((item) => {
        console.log(item, "000");
        item.fileType = this.getFileType(item);
      });
      console.log(val, "fileL22ist==");
    },
  },
  computed: {
    /**
     * 获取文件类型
     */
    getFileType() {
      return (file) => {
        const extIndex = file.url.lastIndexOf(".");
        const fileType = file.url.slice(extIndex + 1).toLocaleLowerCase();
        return fileType;
      };
    },
    /**
     * 将后缀名数组拼接成字符串
     */
    acceptStr() {
      return this.accepts.map((ext) => "." + ext).join(",");
    },
    /**
     * video配置项
     */
    videoPlayerOptions() {
      return (videoUrl) => {
        const videoPlayerOptions = {
          playbackRates: [0.75, 1.0, 1.25, 1.5, 2.0], // 播放速度
          autoplay: false, // 是否自动播放。
          muted: false, // 是否静音播放，默认情况下将会消除任何音频。
          loop: false, // 是否循环播放。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 是否流体从而按比例缩放以适应其容器。
          flash: { hls: { withCreadentials: false } }, // 可以播放rtmp视频
          html5: { hls: { withCreadentials: false } }, // 可以播放m3u8视频
          sources: [
            {
              type: "video/mp4",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/avi",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/wmv",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/flv",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/rmvb",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/3gp",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/m4v",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/mkv",
              src: videoUrl, // 视频url地址
            },
            {
              type: "video/mov",
              src: videoUrl, // 视频url地址
            },
          ],
          width: "100%",
          notSupportedMessage: "此视频暂无法播放...", // 当无法播放时允许覆盖Video.js，显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true,
          },
        };
        return videoPlayerOptions;
      };
    },
  },
  methods: {
    /**
     * 关闭预览
     */
    onHandleClose() {
      this.loading = true;
      this.$emit("input", false);
    },
    /**
     * 弹窗显示/隐藏
     */
    onSetActiveItem() {
      this.$refs.refCarousel?.setActiveItem(this.fileIndex);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.block {
  /deep/ .el-carousel {
    height: 600px;
    &__container {
      height: 100%;
    }
    &__item {
      .img {
        background-size: cover;
        background-position: center;
        overflow: hidden;
        transform: rotate(0deg);
      }
    }
    &__indicator {
      padding: 5px;
    }
    &__button {
      width: 20px;
      height: 3px;
    }
    &__indicators,
    &__arrow {
      display: block;
    }
    &.hide-btn {
      .el-carousel {
        &__indicators,
        &__arrow {
          display: none;
        }
      }
    }
  }
}
</style>
